import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Container, Paper, withStyles } from '@material-ui/core';
import _, { isEqual } from 'lodash';

import Edit from '@material-ui/icons/Edit';
/** COMPONENTS */
import AgentDashboardBanner from 'components/Web/Dashboard/AgentDashboardBanner';
import BannerImage from 'assets/images/agentbanner.png';
import AgentBreadCrumbs from 'components/Web/Agent/AgentBreadCrumbs';

/** CONSTANTS */
import { propertyConfigSetter } from 'utils/property.utils';
/** API */
import { requestPropertyDetails } from 'store/api/property.api';
import PropertyCard from 'components/Web/Agency/PropertyCard';
import EditPropertyInformation from 'components/Common/EditProperty/EditPropertyInformation';
import { getPropertyDetails, getStatus, getErrors, getUser } from 'store/selectors/agencyv2.selectors';
import { getUploadSOI, getUploadSOIStatus, getUploadSOIError } from 'store/selectors/agent.selectors';
import { requestPUTAPIAgency, requestPOSTAPIAgency, requestUploadStatementOfIntent } from 'store/api';
import ImageSelectorDialog from 'components/Web/CustomDialogs/ImageSelectorDialog';
import ButtonStyleFileUpload from 'components/Web/Form/ButtonStyleFileUpload';
import NotificationDialog from 'components/Web/CustomDialogs/NotificationDialog';
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import { agencyv2Actions } from 'store/actions';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { clearAgentState } from 'store/actions';
import { formatUSD } from 'utils/formatter/priceFormatter';
import { propertyDetailsClear } from 'store/actions';

import { useRouterChange } from 'utils/router.utils';
import { Loader } from 'components/Common';

/** STYLES */
const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
    color: '#4d4d4d',
  },
  dashboardContainer: {
    fontFamily: 'Montserrat',
    margin: '0 auto',
    padding: 0,
    boxShadow: 'none',
  },
  contentContainer: {
    maxWidth: 1012,
    margin: '0 auto',
    '@media (max-width: 1024px)': {
      paddingLeft: 93,
      paddingRight: 80,
    },
    '@media (max-width: 768px)': {
      paddingLeft: 130,
    },
  },
  title: {
    fontSize: 21,
    fontWeight: 700,
    lineHeight: '32px',
    marginBottom: 40,
    textTransform: 'capitalize',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '32px',
  },
  section: {
    padding: '40px 0 50px',
    borderTop: '1px solid #CDCDCD',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'start',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  sectionTitleContainer: {
    flex: '0 0 200px',
    '@media (max-width: 768px)': {
      flex: '0 0 40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  sectionTypeContainer: {
    flex: '0 0 330px',
    position: 'relative',
    '@media (max-width: 1024px)': {
      flex: '0 0 250px',
    },
    '@media (max-width: 768px)': {
      flex: '0 0 40px',
      marginBottom: 16,
    },
  },
  cardWrapper: {
    '& > div > div': {
      marginTop: 0,
    },
  },
  sectionActionsContainer: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      alignItems: 'end',
    },
    '@media (max-width: 768px)': {
      alignItems: 'start',
    },
  },
  sectionType: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '23px',
    color: '#000',
  },
  notifyBtn: {
    backgroundColor: theme.palette.primaryGreen.main,
    borderRadius: 20,
    textTransform: 'capitalize',
    paddingLeft: '35px',
    paddingRight: '35px',
    marginRight: 15,
    '&:last-child': {
      marginRight: 0,
    },

    '&:hover': {
      backgroundColor: '#579d80',
    },
  },
  viewSOIBtn: {
    width: 140,
    backgroundColor: '#e0e0e0',
    fontSize: 14,
    textAlign: 'center',
    padding: '9px 35px',
    borderRadius: 20,
    textTransform: 'capitalize',
    marginRight: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ccc',
    },
    '@media (max-width: 1024px)': {
      marginRight: 0,
    },
  },
  uploadSOIBtn: {
    backgroundColor: '#fff',
    fontSize: 14,
    textAlign: 'center',
    // padding: '9px 35px',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 20,
    border: '1px solid #4d4d4d',
    textTransform: 'capitalize',
    marginRight: 16,
    color: '#ec0000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
    },
    '@media (max-width: 1024px)': {
      marginTop: 16,
      marginRight: 0,
    },
  },
  saveDraftBtn: {
    backgroundColor: '#35C0CA',
    fontSize: 14,
    textAlign: 'center',
    padding: '9px 35px',
    borderRadius: 20,
    textTransform: 'capitalize',
    marginRight: 16,
    color: '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2aa2ab',
    },
    '@media (max-width: 1024px)': {
      marginBottom: 16,
      marginRight: 0,
    },
  },
  editPropertyBtn: {
    fontSize: 17,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#6a6a6a',
    backgroundColor: '#fff',
    borderRadius: 23,
    padding: '7px 20px',
    textAlign: 'center',
    textTransform: 'capitalize',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
    marginBottom: 230,
    '@media (max-width: 768px)': {
      marginBottom: 16,
    },
  },
  rejectedBtn: {
    padding: '4px 20px',
    backgroundColor: '#EF7070',
    color: '#000',
    fontSize: 10,
    fontWeight: 300,
    lineHeight: '11px',
    textAlign: 'center',
    textTransform: 'capitalize',
    borderRadius: 20,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
    marginBottom: 14,
    '&:hover': {
      backgroundColor: '#e95d5d',
    },
  },
  pendingApprovalBtn: {
    padding: '4px 20px',
    backgroundColor: theme.palette.primaryYellow.main,
    color: '#ffffff',
    fontSize: 10,
    fontWeight: 300,
    lineHeight: '11px',
    textAlign: 'center',
    textTransform: 'capitalize',
    borderRadius: 20,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
    marginBottom: 14,
    '&:hover': {
      backgroundColor: theme.palette.primaryYellow.main,
    },
  },
  approvedBtn: {
    padding: '4px 20px',
    backgroundColor: '#6DC5A1',
    color: '#ffffff',
    fontSize: 10,
    fontWeight: 300,
    lineHeight: '11px',
    textAlign: 'center',
    textTransform: 'capitalize',
    borderRadius: 20,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
    marginBottom: 14,
    '&:hover': {
      backgroundColor: '#6DC5A1',
    },
  },
  rejectedText: {
    padding: 12,
    '& p': {
      fontSize: 12,
      color: '#4d4d4d',
    },

    maxWidth: 163,
    '&:active, &:focus-visible': {
      borderColor: '#4d4d4d',
      outline: 'none',
    },
    '@media (max-width: 768px)': {
      marginBottom: 16,
    },
  },
  genericButton: {
    textTransform: 'initial',
    borderRadius: 20,
    paddingLeft: '35px',
    paddingRight: '35px',
    marginRight: 15,
    fontSize: 14,
    '&:last-child': {
      marginRight: 0,
    },
  },
  chooseImageBtn: {
    borderRadius: 20,
    textTransform: 'initial',
    fontSize: 9,
    position: 'absolute',
    top: 9,
    right: 10,
    backgroundColor: '#ffffff',
  },
});

/** DEFAULT */
const CRUMBS = [
  {
    type: 'secondary',
    label: 'Home',
    path: '/',
  },
  {
    type: 'secondary',
    label: 'Agency Dashboard',
    path: '/agency/dashboard',
  },
  {
    type: 'secondary',
    label: 'Manage Properties',
    path: '/agency/manage-properties',
  },
  {
    type: 'primary',
    label: 'Edit Property',
    path: '/agent/edit-property',
  },
];

const EditProperty = (props) => {
  const { classes, history } = props;
  const [routerChange] = useRouterChange();
  const pathSegments = history.location.pathname.split('/');
  const id = pathSegments[pathSegments.length - 1];
  const propertyId = history.location.pathname.split('/')[3] ? history.location.pathname.split('/')[3] : id ? id : null;
  const dispatch = useDispatch();
  const [propertyDetails, setPropertyDetails] = useState(null);

  const [currentProperty, setCurrentProperty] = useState(null);

  const [isDraftClicked, setIsDraftClicked] = useState(false);
  const [isPublishClicked, setIsPublishClicked] = useState(false);
  const [referenceProperty, setReferenceProperty] = useState({});
  const [changesBucket, setChangesBucket] = useState(null);
  const [hasChanges, setHasChanges] = useState(null);
  const [selectCoverToggle, setSelectCoverToggle] = useState(false);
  const placeholderimages = [
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door1_1600px_fikeod.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door2_1600px_r7smbu.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door3_1600px_m0tlls.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door4_1600px_icfuwh.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door5_1600px_xctche.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door6_1600px_finaxe.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door7_1600px_w2mvj4.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door8_1600px_y9mfst.jpg',
    'https://res.cloudinary.com/dyc2qhrgg/image/upload/l_pmatelogo:XTRANSFORMX,w_200/f_webp,q_auto,w_1000/v1634714085/default-cover-images/Door10_1600px_rhy8wm.jpg',
  ];
  const [defaultImages, setDefaultImages] = useState();
  const [coverSelected, setCoverSelected] = useState(null);
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [propertyStatus, setPropertyStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [showNotification, setShowNotification] = useState(false);

  const [draftSaved, setDraftSaved] = useState(false);

  const apiGetPropertyDetails = (id) => {
    dispatch(requestPropertyDetails(id));
  };

  const apiRequestUploadStatementOfInfo = (data) => {
    dispatch(requestUploadStatementOfIntent(data));
  };

  const upload_status = useSelector(getUploadSOIStatus);
  const upload_error = useSelector(getUploadSOIError);
  const uploaded_intent = useSelector(getUploadSOI);

  const changes_status = useSelector(getStatus);
  const changes_errors = useSelector(getErrors);

  const USER = useSelector(getUser);

  const property = useSelector(getPropertyDetails);

  const {
    propertyType,
    addressSubNumber,
    addressLotNumber,
    addressStreetNumber,
    addressStreetName,
    addressSuburb,
    addressPostcode,
    addressState,
    price,
    priceView,
    bedrooms,
    bathrooms,
    carparking,
    propertyStatus: currentPropertyStatus,
  } = propertyDetails ?? {};

  const currentPropertyFields = {
    propertyType,
    addressSubNumber,
    addressLotNumber,
    addressStreetNumber,
    addressStreetName,
    addressSuburb,
    addressPostcode,
    addressState,
    price,
    priceView,
    bedrooms,
    bathrooms,
    carparking,
    currentPropertyStatus,
  };

  const currentActiveFields = {
    propertyType: currentProperty?.propertyType,
    addressSubNumber: currentProperty?.addressSubNumber,
    addressLotNumber: currentProperty?.addressLotNumber,
    addressStreetNumber: currentProperty?.addressStreetNumber,
    addressStreetName: currentProperty?.addressStreetName,
    addressSuburb: currentProperty?.addressSuburb,
    addressPostcode: currentProperty?.addressPostcode,
    addressState: currentProperty?.addressState,
    price: currentProperty?.price,
    priceView: currentProperty?.priceView,
    bedrooms: currentProperty?.bedrooms,
    bathrooms: currentProperty?.bathrooms,
    carparking: currentProperty?.carparking,
    currentPropertyStatus: currentProperty?.propertyStatus,
  };

  const isAnyOfFieldsChange = isEqual(currentActiveFields, currentPropertyFields);

  const isPublishing = changes_status === 'agency/AGENCY_PUBLISH_PROPERTY_REQUEST';

  const isSavingDraft = changes_status === 'agency/AGENCY_EDIT_PROPERTY_REQUEST';

  const handleSaveDraft = () => {
    setIsDraftClicked(true);
    if (hasChanges) {
      const params = {
        query: currentProperty._id,
        data: { ...hasChanges, priceView: currentProperty.priceView },
      };
      setShowNotification(true);
      dispatch(requestPUTAPIAgency(params, 'AGENCY_EDIT_PROPERTY'));
    }
  };

  const handlePublish = async () => {
    setIsPublishClicked(true);
    const params = {
      query: currentProperty._id,
      data: hasChanges ? { ...hasChanges, isApprovedByAgency: true, priceView: currentProperty?.priceView } : null,
    };

    setShowNotification(true);

    const promises = [];
    if (hasChanges) {
      promises.push(dispatch(requestPUTAPIAgency(params, 'AGENCY_EDIT_PROPERTY')));
    }
    promises.push(dispatch(requestPOSTAPIAgency(params, 'AGENCY_PUBLISH_PROPERTY')));

    await Promise.all(promises);
  };

  const handleNotifyVendor = () => {
    const params = {
      query: currentProperty._id,
      data: null,
    };
    dispatch(requestPOSTAPIAgency(params, 'AGENCY_NOTIFY_VENDOR'));
  };

  const handleImageSelectClose = () => {
    setSelectCoverToggle(false);
  };

  const handleImageSelectConfirm = (value) => {
    setSelectCoverToggle(false);
    setCoverSelected(value);
    // handleFieldChange('propertyInfo', null, 'customCover', true)
    handleChanges({ images: [value] });
    setSelectCoverToggle(false);
  };

  /*const handleChanges = (data) => {
    setCurrentProperty({ ...currentProperty, ...data });
    setChangesBucket({ ...changesBucket, ...data });
  };*/

  const handleChanges = (data) => {
    setCurrentProperty((prevCurrentProperty) => ({ ...prevCurrentProperty, ...data }));
    setChangesBucket((prevChangesBucket) => ({ ...prevChangesBucket, ...data }));
  };

  const handleBucketChanges = (data) => {
    setChangesBucket((prev) => ({ ...prev, ...data }));
  };

  window.onbeforeunload = (e) => {
    return dispatch(clearAgentState());
  };

  useEffect(() => {
    dispatch(clearAgentState());
    dispatch(propertyDetailsClear());

    return () => {
      dispatch(clearAgentState());
      dispatch(propertyDetailsClear());
    };
  }, []);

  useEffect(() => {
    apiGetPropertyDetails({ property: propertyId });
    handleClearError();
    handleClearStatus();
  }, []);

  useEffect(() => {
    if (USER && !isAgency()) {
      const options = {
        status: true,
        options: {
          severity: 'warning',
          message: 'User Type has not enough permission to view this page.',
          autoHideDuration: 3000,
          onClose: () => routerChange('/agent/login'),
        },
      };
      setIsNotification(options);
      routerChange('/agent/login');
    } else if (!USER && !isAgency()) {
      routerChange('/agency/login');
    }
  }, [USER]);

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'));
  };

  useEffect(() => {
    if (currentProperty && property) {
      const getTransformations = currentProperty.propertyStatus === 'comingsoon' ? 'logoyellow' : 'logowhite';
      const imgBucket = [];
      placeholderimages.map((item, i) => {
        imgBucket.push(item.replace('XTRANSFORMX', getTransformations));
      });
      setDefaultImages(imgBucket);
    }
  }, [currentProperty, property]);

  useEffect(() => {
    if (changesBucket) {
      const o = changesBucket;
      delete o.priceViewchangesBucket;
      delete o.showPrice;

      const getKeys = Object.keys(changesBucket);
      let temp = changesBucket;

      getKeys.map((item) => (changesBucket[item] === referenceProperty[item] ? delete temp[item] : false));

      if (Object.keys(temp).length) {
        setHasChanges(temp);
      } else {
        setHasChanges(null);
      }
    }
  }, [changesBucket]);

  useEffect(() => {
    if (hasChanges?.statementOfIntent) {
      handleSaveDraft();
    }
  }, [hasChanges]);

  useEffect(() => {
    if (property && property.data && property.data.data && property.data.data.property) {
      setPropertyDetails(property.data.data.property);
      const theProperty = property.data.data.property;
      const refParams = {
        addressText: theProperty.addressText,
        propertyStatus: theProperty.propertyStatus,
        price: theProperty.price,
        propertyType: theProperty.propertyType,
        totalParking: theProperty.totalParking,
        carports: theProperty.carports,
        bathrooms: theProperty.bathrooms,
        bedrooms: theProperty.bedrooms,
      };

      setReferenceProperty(refParams);
    }
  }, [property]);

  useEffect(() => {
    if (propertyDetails) {
      setCurrentProperty(propertyDetails);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (changes_status === 'agency/AGENCY_PUBLISH_PROPERTY_SUCCESS') {
      setTimeout(() => {
        routerChange('/agency/manage-properties');
      }, 1000);
    }
  }, [changes_status]);

  useEffect(() => {
    if (changes_status === 'agency/AGENCY_EDIT_PROPERTY_SUCCESS' && isDraftClicked) {
      const options = {
        status: true,
        options: { severity: 'success', message: 'Draft saved successfully!' },
      };
      if (showNotification) {
        setIsNotification(options);
      }
      resetChanges();
      setDraftSaved(true);
      setIsDraftClicked(false);
    }

    if (changes_status === 'agency/AGENCY_EDIT_PROPERTY_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error saving draft, please try again.',
        },
      };
      if (showNotification) {
        setIsNotification(options);
      }
    }

    if (changes_status === 'agency/AGENCY_PUBLISH_PROPERTY_SUCCESS' && isPublishClicked) {
      const options = {
        status: true,
        options: { severity: 'success', message: 'Property published successfully!' },
      };
      setIsNotification(options);
      resetChanges();
      setDraftSaved(false);
      setIsPublishClicked(false);
    }

    if (changes_status === 'agency/AGENCY_PUBLISH_PROPERTY_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error publishing property, please try again.',
        },
      };
      setIsNotification(options);
    }

    if (changes_status === 'agency/AGENCY_NOTIFY_VENDOR_SUCCESS') {
      const options = {
        status: true,
        options: { severity: 'success', message: 'Vendor notified successfully!' },
      };
      setIsNotification(options);
      resetChanges();
    }

    if (changes_status === 'agency/AGENCY_NOTIFY_VENDOR_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error sending notification to vendor. Try again.',
        },
      };
      setIsNotification(options);
    }
    if (changes_status === 'agency/AGENCY_NOTIFY_VENDOR_REQUEST') {
      const options = {
        status: true,
        options: { severity: 'info', message: 'Sending vendor notification...' },
      };
      setIsNotification(options);
    }
  }, [changes_errors, changes_status]);

  useEffect(() => {
    let timeout;
    if (upload_status === 'success' && !upload_error) {
      handleChanges({ statementOfIntent: uploaded_intent.uploadedFiles[0] });
      setConfirmUpload(false);
      setLoading(false);
    } else if (upload_error && files) {
      setLoading(false);
      setUploadError(true);
      setConfirmUpload(false);
      if (upload_error.status === 400 || upload_error.status === 401) {
        timeout = setTimeout(() => {
          // logoutUser();
          setUploadError(false);
          window.location.reload();
        }, 4000);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [upload_status, upload_error]);

  const handleUploadConfirmation = (mode) => {
    var uploadData = new FormData();
    if (files && mode === 'upload') {
      setLoading(true);
      for (const file of files) {
        uploadData.append('soi', file, file.name);
      }
    }
    apiRequestUploadStatementOfInfo(uploadData);
    setConfirmUpload(false);
  };

  const resetChanges = () => {
    setHasChanges(false);
    setChangesBucket(null);
    setCurrentProperty(null);
    setReferenceProperty({});
    handleClearError();
    handleClearStatus();
    apiGetPropertyDetails({ property: propertyId });
  };

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const isAgency = () => {
    return USER?.userType.toLowerCase() === 'agency' ||
      USER?.userTypeList.some((i) => ['Agency', 'AgencyAdmin'].includes(i))
      ? true
      : false;
  };

  const isPublished = !!propertyDetails?.publishDate;

  return (
    <div className={classes.root}>
      {/* BANNER */}

      <Loader isLoading={loading} />

      {isAgency() && (
        <>
          <div className={classes.dashboardContainer}>
            <AgentDashboardBanner
              title={'Edit Property'}
              content={'A great property sale requires a great agent. Manage your agents here.'}
              image={BannerImage}
            />
          </div>
          {/* END OF BANNER */}

          <Container>
            <AgentBreadCrumbs crumbsLinks={CRUMBS} />
          </Container>

          <div className={classes.contentContainer}>
            <Typography variant={'h1'} className={classes.title}>
              Edit Property
            </Typography>
            <Typography variant={'h2'} className={classes.subtitle}>
              Steps for agents to update properties
            </Typography>

            {/* STEP 01 */}
            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <Typography variant={'h3'} className={classes.title} style={{ marginBottom: 0 }}>
                  Step 01
                </Typography>
              </div>
              <div className={classes.sectionTypeContainer}>
                <Typography variant={'body1'} className={classes.sectionType}>
                  Notify vendor for approval
                </Typography>
              </div>
              <div className={classes.sectionActionsContainer}>
                <Button
                  variant={'contained'}
                  size={'small'}
                  disableElevation
                  onClick={handleNotifyVendor}
                  className={classes.notifyBtn}
                  disabled={
                    currentProperty?.isApprovedByVendor ||
                    (currentProperty?.vendor?.email === '' && currentProperty?.vendor?.phone === '')
                  }
                >
                  Notify Vendor for Approval
                </Button>
              </div>
            </div>
            {/* END OF STEP 01 */}

            {/* STEP 02 */}
            {currentProperty &&
              currentProperty.addressState === 'VIC' &&
              (currentProperty?.propertyStatus === 'comingsoon' || currentProperty?.propertyStatus === 'offmarket') && (
                <div className={classes.section}>
                  <div className={classes.sectionTitleContainer}>
                    <Typography variant={'h3'} className={classes.title} style={{ marginBottom: 0 }}>
                      Step 02
                    </Typography>
                  </div>
                  <div className={classes.sectionTypeContainer}>
                    <Typography variant={'body1'} className={classes.sectionType}>
                      Upload final SOI {'(optional)'}
                    </Typography>
                  </div>
                  <div className={classes.sectionActionsContainer}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      size={'small'}
                      disabled={currentProperty && currentProperty?.statementOfIntent ? false : true}
                      className={classes.genericButton}
                      href={currentProperty && currentProperty?.statementOfIntent}
                      target={'blank'}
                      disableElevation
                    >
                      View SOI
                    </Button>
                    {isAgency() && (
                      <ButtonStyleFileUpload
                        label={
                          uploaded_intent && upload_status && upload_status === 'success'
                            ? uploaded_intent.uploadedFiles[0]
                            : 'Upload Statement Of Information'
                        }
                        onChange={(e) => {
                          const maxFileSizeInKB = 20000000;
                          if (e.target.files.length > 0) {
                            if (e.target.files[0].size <= maxFileSizeInKB) {
                              setFileName(e.target.files[0].name);
                              setFiles(e.target.files);
                              setConfirmUpload(true);
                            } else {
                              setFileSizeError(true);
                            }
                          }
                        }}
                        disabled={uploaded_intent && upload_status && upload_status === 'success'}
                        style={{ padding: '3px 12px' }}
                        disableElevation
                      />
                    )}
                  </div>
                </div>
              )}
            {/* END OF STEP 02 */}

            {/* STEP 03 */}
            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <Typography variant={'h3'} className={classes.title} style={{ marginBottom: 0 }}>
                  Step{' '}
                  {currentProperty &&
                  currentProperty.addressState === 'VIC' &&
                  currentProperty.propertyStatus === 'comingsoon'
                    ? '03'
                    : '02'}
                </Typography>
              </div>
              <div className={classes.sectionTypeContainer}>
                <Typography variant={'body1'} className={classes.sectionType}>
                  Publish Property
                </Typography>
              </div>
              <div className={classes.sectionActionsContainer}>
                {/* <Button className={classes.saveDraftBtn} disabled={!hasChanges}>Save Draft</Button> */}
                {!isPublished && (
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    size={'small'}
                    disabled={!hasChanges || isSavingDraft || isPublishing}
                    className={classes.genericButton}
                    disableElevation
                    //onClick={handleSaveDraft}
                    onClick={() => {
                      handleSaveDraft();
                      setShowNotification(true);
                    }}
                  >
                    {isSavingDraft ? 'Saving' : 'Save Draft'}
                  </Button>
                )}
                <Button
                  className={classes.genericButton}
                  disabled={
                    isPublishing ||
                    isSavingDraft ||
                    (isPublished && (isPublishing || isSavingDraft || isAnyOfFieldsChange))
                    //!currentProperty?.isApprovedByVendor ||

                    // disable coming soon required SOI for coming soon properties for under victoria suburb
                    // !currentProperty?.statementOfIntent ||
                    // currentProperty?.statementOfIntent === null ||
                    // currentProperty?.statementOfIntent.length === 0
                    // currentProperty?.addressState === 'VIC' &&
                    // currentProperty?.propertyStatus === 'comingsoon'
                  }
                  variant={'contained'}
                  color={'primary'}
                  size={'small'}
                  disableElevation
                  onClick={handlePublish}
                >
                  {isPublishing ? 'Saving...' : `${isPublished ? 'Save and' : ''} Publish`}
                </Button>
              </div>
            </div>
            {/* END OF STEP 03 */}

            {/* STEP 04 */}
            <div className={classes.section}>
              <div className={classes.sectionTitleContainer}>
                <Button className={classes.editPropertyBtn} endIcon={<Edit />}>
                  Edit Property
                </Button>

                {(currentProperty?.vendor?.email !== '' || currentProperty?.vendor?.phone !== '') &&
                !currentProperty?.isApprovedByVendor &&
                !currentProperty?.vendor?.remarks ? (
                  <Button className={classes.pendingApprovalBtn}>Vendor Pending Approval</Button>
                ) : null}
                {currentProperty?.isApprovedByVendor &&
                (currentProperty?.vendor?.email || currentProperty?.vendor?.phone) ? (
                  <Button className={classes.approvedBtn}>Vendor Approved</Button>
                ) : null}

                {currentProperty && !currentProperty.isApprovedByVendor && currentProperty?.vendor?.remarks && (
                  <>
                    <Button className={classes.rejectedBtn}>Vendor Rejected</Button>
                    <Paper className={classes.rejectedText}>
                      <Typography>{currentProperty?.vendor?.remarks}</Typography>
                    </Paper>
                  </>
                )}
              </div>
              <div className={classes.sectionTypeContainer}>
                {currentProperty && (
                  <div className={classes.cardWrapper}>
                    <PropertyCard
                      cardType={'list'}
                      listingType={'agent-properties'}
                      property={currentProperty}
                      {...propertyConfigSetter('card-type', 'premium')}
                    />
                  </div>
                )}
                {isAgency() && (
                  <Button
                    variant={'contained'}
                    className={classes.chooseImageBtn}
                    onClick={() => setSelectCoverToggle(true)}
                    endIcon={<Edit />}
                    size={'small'}
                  >
                    Edit Cover Image
                  </Button>
                )}
              </div>
              <div className={classes.sectionActionsContainer}>
                <EditPropertyInformation
                  property={currentProperty}
                  updateCallback={handleChanges}
                  disabled={!isAgency()}
                  handleBucketChanges={handleBucketChanges}
                  setPropertyStatus={setPropertyStatus}
                  propertyStatus={propertyStatus}
                />
              </div>
            </div>
            {/* END OF STEP 04 */}
          </div>
          <ImageSelectorDialog
            isOpen={selectCoverToggle}
            closeCallback={handleImageSelectClose}
            confirmCallback={handleImageSelectConfirm}
            showLogo={false}
            align={'center'}
            title={'Choose an image as your cover'}
            text={''}
            imageOptions={defaultImages}
          />
          {fileSizeError && (
            <NotificationDialog
              isOpen={fileSizeError}
              closeCallback={() => {
                setFileSizeError(false);
              }}
              title={'File uploaded exceeded on limit'}
              text="The file your trying to upload exceed the limit of 20MB please try another file"
              showLogo={true}
              align={'center'}
            />
          )}

          {uploadError && (
            <NotificationDialog
              isOpen={uploadError}
              closeCallback={() => {
                setUploadError(false);
              }}
              title={'Error'}
              text={
                upload_error.status === 401 || upload_error.status === 400
                  ? 'Your session has expired or invalid. You will be asked to re-login after few seconds.'
                  : 'Error encountered when uploading captured image.'
              }
              showLogo={true}
              align={'center'}
            />
          )}
          <ConfirmDialog
            isOpen={confirmUpload}
            confirmCallback={() => handleUploadConfirmation('upload')}
            closeCallback={() => setConfirmUpload(false)}
            showLogo={true}
            align={'center'}
            title={'Upload Statement of Information'}
            text={'Are you sure you want to upload ' + `${fileName ? fileName : 'selected file'}` + '?'}
          />
        </>
      )}

      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(EditProperty);
