import { Box, Button, Grid, makeStyles, TextField, Typography, Checkbox, Stack } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Loader } from 'components/Common';
import StripePaymentForm from 'components/Common/Billing/StripePaymentForm';
import PromoCode from 'components/Common/Billing/PromoCode';
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import CustomDatePicker from 'components/Web/Filter/CustomDatePicker';
import CustomSelect from 'components/Web/Filter/CustomSelect';
import AlertNotification from 'components/Web/Form/AlertNotification';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { billingActions } from 'store/actions/billing.actions';
import { StorageActions } from 'store/actions/storage.actions';
import { requestPOSTAPIBilling } from 'store/api/billing.api';
import { requestGETAPIBilling } from 'store/api/billing.api';
import { getClientSecret } from 'store/selectors/billing.selectors';
import { getPricePackages } from 'store/selectors/billing.selectors';
import { getUser } from 'store/selectors/superadmin.selectors';
import { cleanDateToday } from 'utils/cleanDateToday';
import { computeDateDiff } from 'utils/computeDateDiff';
import useHandleManageAgencyRowCount from 'utils/customHooks/useHandleManageAgencyRowCount';
import useHandleManageAgencyPageCount from 'utils/customHooks/useHandleManageAgencyPageCount';
import useHandleManageAgencyButtons from 'utils/customHooks/useHandleManageAgencyButtons';
import useHandleManageAgencyFilters from 'utils/customHooks/useHandleManageAgencyFilters';
import useHandleManageAgencySort from 'utils/customHooks/useHandleManageAgencySort';
import useHandleManageAgencySearchField from 'utils/customHooks/useHandleManageAgencySearchField';
import { useRouterChange } from 'utils/router.utils';

const useStyles = makeStyles({
  docusignButton: {
    width: '70%',
    height: '50px',
    borderRadius: '40px',
    backgroundColor: '#fdbd26',
    borderColor: '#fdbd26',
    '&:hover': {
      backgroundColor: 'rgba(253, 189, 38, 0.8)',
    },
  },
});

const API_KEY = process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const ACCOUNT_ID = process?.env?.REACT_APP_STRIPE_ACCOUNT_ID;
const stripePromise = loadStripe(API_KEY, { stripeAccount: ACCOUNT_ID });

const FormInput = (props) => (
  <div style={{ marginTop: 10 }}>
    <Typography variant={'caption'} style={{ color: 'gray' }}>
      {props.label}
    </Typography>
    <TextField
      style={{ backgroundColor: '#FFF' }}
      {...props}
      label={null}
      fullWidth
      id="outlined-basic"
      variant="outlined"
    />
  </div>
);

const stripePackageHandler = (stripePackages) => {
  const selectPackagesField = [];

  let singlePackage = {};

  if (stripePackages) {
    stripePackages.forEach((stripepackage) => {
      if (stripepackage.subscriptionType !== 'EXTENDED FREE') {
        stripepackage.productPriceList.forEach((product) => {
          singlePackage = { label: stripepackage.name };
          singlePackage = {
            label: `${singlePackage.label} $${product.price}/${product.type}`,
            id: product.id,
            value: stripepackage.subscriptionType + '-' + product.billingPeriodType,
          };
          selectPackagesField.push(singlePackage);
        });
      }
    });
  }

  return selectPackagesField;
};

const BillingInfo = ({ packages, showStripeForm, handleShowStripeForm, trialDays, trialDaysHandler, routerChange }) => {
  const today = cleanDateToday();

  const [rowCount, setRowCount] = useHandleManageAgencyRowCount();
  const [pageCount, setPageCount] = useHandleManageAgencyPageCount();
  const [manageButtons, setManageButtons] = useHandleManageAgencyButtons();
  const [manageFilters, setManageFilters] = useHandleManageAgencyFilters();
  const [manageSort, setManageSort] = useHandleManageAgencySort();
  const [manageSearchField, setManageSearchField] = useHandleManageAgencySearchField();
  const [startDate, setStartDate] = useState(today);

  const [stripePackage, setStripePackage] = useState();
  const [packageOptions, setPackageOptions] = useState();
  const [isSelectPackageDisabled, setIsSelectPackageDisabled] = useState(false);
  const [isSkipButtonDisabled, setIsSkipButtonDisabled] = useState(false);

  const [packageError, setPackageError] = useState(false);
  const [message, setMessage] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,

    title: '',
    message: '',
  });

  const dispatch = useDispatch();
  const AGENCY_DETAILS = useSelector(getUser);

  const apiRequestGetClientSecret = (params) => {
    const request = {
      query: null,
      data: params,
    };
    handleGetClientSecretClear();
    dispatch(requestPOSTAPIBilling(request, 'STRIPE_POST_CLIENTSECRET', 'REQUEST'));
  };

  const handleStripePackage = (data) => {
    setStripePackage(data);
  };

  const handleGetClientSecretClear = () => {
    dispatch(billingActions(null, 'STRIPE_POST_CLIENTSECRET', 'CLEAR'));
  };

  const handleSelectPackage = () => {
    const agencyDetails = AGENCY_DETAILS?.currentAddedAgency?.data?.entity;
    const filteredPackage = packageOptions.filter((product) => product.value === stripePackage);
    const priceId = filteredPackage[0].id;

    const request = {
      priceId: priceId,
      email: agencyDetails?.adminEmail,
      name: agencyDetails?.firstName + ' ' + agencyDetails?.lastName,
      trialPeriodDayCount: trialDays,
      agencyReference: agencyDetails?._id,
      startDate: startDate,
    };

    if (request.priceId?.length === 0) {
      setPackageError(true);
      setMessage('Please pick a package');
    } else {
      setPackageError(false);
      setMessage(null);
    }

    if (request.email?.length === 0) {
      setPackageError(true);
      setMessage('Email is required to process card details');
    } else {
      setPackageError(false);
      setMessage(null);
    }

    if (request.name?.trim()?.length === 0) {
      setPackageError(true);
      setMessage('Name of customer is required to process card details');
    } else {
      setPackageError(false);
      setMessage(null);
    }

    if (!packageError) {
      apiRequestGetClientSecret(request);
    }
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog((prev) => ({ ...prev, open: false }));
  };

  const handleConfirmDialogCallback = () => {
    handleShowStripeForm();
    handleSelectPackage();
    setIsSelectPackageDisabled(true);
    setIsSkipButtonDisabled(false);
    setConfirmDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const calculatedBillingDate = useMemo(() => {
    let new_date = moment(startDate, 'DD-MM-YYYY').add('days', trialDays);
    return new_date;
  }, [startDate, trialDays]);

  useMemo(() => {
    setPackageOptions(stripePackageHandler(packages));
  }, [packages]);

  useEffect(() => {
    if (stripePackage) {
      setIsSelectPackageDisabled(false);
    }
  }, [stripePackage]);

  const calculatedDateValue = useMemo(() => {
    let new_date = moment(startDate, 'DD-MM-YYYY').add('days', trialDays);
    let day = new_date.format('DD');
    let month = new_date.format('MM');
    let year = new_date.format('YYYY');
    return day + '/' + month + '/' + year;
  }, [startDate, trialDays]);

  const handleManageAgencyPageReset = () => {
    setRowCount(30);
    setPageCount(0);
    setManageButtons([
      {
        id: 0,
        label: 'Paid',
        active: true,
      },
      {
        id: 1,
        label: 'Free',
        active: false,
      },
    ]);
    setManageFilters({ subscription: 'ALL', docusign: 'ALL', payment: 'ALL', adminEmail: 'ALL', active: 'ALL' });
    setManageSort([
      {
        id: 0,
        key: 'registeredTradeName',
        sort: false,
        sortOrder: null,
      },
      {
        id: 1,
        key: 'address',
        sort: false,
        sortOrder: null,
      },
      {
        id: 2,
        key: 'adminEmail',
        sort: false,
        sortOrder: null,
      },
      {
        id: 3,
        key: 'subscription.billingDate',
        sort: false,
        sortOrder: null,
      },
    ]);
    setManageSearchField('');
  };

  return (
    <Box>
      <ConfirmDialog
        isOpen={confirmDialog.open}
        confirmCallback={() => handleConfirmDialogCallback()}
        closeCallback={() => handleConfirmDialogClose()}
        showLogo={true}
        align={'center'}
        title={confirmDialog.title}
        text="Can only edit the package on manage agencies, do you wish to continue with the chosen package"
      />

      <Box marginTop="10px">
        <CustomSelect
          styles={{
            disabled: showStripeForm,
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          isForm={true}
          label="Package"
          options={packageOptions}
          handleSelect={handleStripePackage}
          isClearFilter={false}
          value={stripePackage}
        />
      </Box>

      {/* <FormInput
        disabled={showStripeForm}
        fullWidth
        id="trialDays"
        name="trialDays"
        label="Trial Days"
        type="number"
        variant="outlined"
        value={trialDays}
        onChange={(event) => {
          trialDaysHandler(event.target.value)
        }}
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      // error={formik.touched.trialDays && Boolean(formik.errors.trialDays)}
      // helperText={formik.touched.trialDays && formik.errors.trialDays}
      /> */}
      <Box marginTop="10px">
        <CustomDatePicker
          styles={{ disabled: showStripeForm }}
          isForm={true}
          label="Start Date"
          value={startDate}
          handleDate={(value) => setStartDate(value)}
          isClearFilter={false}
          style={{ margin: '0px' }}
          minDate={today}
        />
      </Box>

      <Box marginTop="10px">
        <CustomDatePicker
          styles={{ disabled: showStripeForm }}
          isForm={true}
          label="Billing Date"
          value={calculatedBillingDate}
          handleDate={(value) => {
            trialDaysHandler(computeDateDiff(startDate, value));
          }}
          isClearFilter={false}
          style={{ margin: '0px' }}
          minDate={today}
        />
      </Box>

      <Box
        marginTop="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridGap="15px"
        flexDirection="column"
      >
        <Button
          disabled={stripePackage && !isSelectPackageDisabled ? false : true}
          color={'primary'}
          variant="contained"
          style={{
            width: '70%',
            height: '50px',
            borderRadius: '40px',
            color: 'white',
          }}
          onClick={() => {
            handleManageAgencyPageReset();

            setConfirmDialog((prev) => ({
              ...prev,
              title: stripePackage,
              open: true,
            }));
          }}
        >
          Continue
        </Button>
        <Button
          color={'primary'}
          disabled={isSkipButtonDisabled}
          variant="outline"
          style={{
            width: '70%',
            height: '50px',
            borderRadius: '40px',
            backgroundColor: 'transparent',
            color: isSkipButtonDisabled ? 'rgba(0, 0, 0, 0.12)' : '#35C0CA',
          }}
          onClick={() => {
            handleManageAgencyPageReset();

            routerChange(`/superadmin/manage-agencies/${AGENCY_DETAILS?.currentAddedAgency?.data?.entity?._id}`);
          }}
        >
          Skip payment method
        </Button>
      </Box>
    </Box>
  );
};

const AgencyBillingForm = () => {
  const [routerChange] = useRouterChange();
  const [trialDays, setTrialDays] = useState(90);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [isUsePromoCode, setIsUsePromoCode] = useState(false);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  const dispatch = useDispatch();
  const PACKAGES = useSelector(getPricePackages);
  const CLIENTSECRET = useSelector(getClientSecret);
  const AGENCY_DETAILS = useSelector(getUser);

  const handleShowStripeForm = () => {
    setShowStripeForm((prev) => !prev);
  };

  const handleGetPackageClear = () => {
    dispatch(billingActions(null, 'STRIPE_GET_PRICES', 'CLEAR'));
  };

  const trialDaysHandler = (value) => {
    setTrialDays(value);
  };

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const apiRequestGetPricePackages = () => {
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClear();
    dispatch(requestGETAPIBilling(request, 'STRIPE_GET_PRICES', 'REQUEST'));
  };

  useEffect(() => {
    apiRequestGetPricePackages();
  }, []);

  useEffect(() => {
    if (showStripeForm) {
      if (!clientSecret) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
  }, [showStripeForm, clientSecret]);

  useEffect(() => {
    if (CLIENTSECRET) {
      if (
        CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_SUCCESS' ||
        CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_FAILED'
      ) {
        setIsLoading(false);
      }

      if (CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_SUCCESS') {
        // setClientSecret(CLIENTSECRET?.data?.clientSecret);
      }

      if (CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_FAILED') {
        handleNotification(true, {
          severity: 'error',
          message: 'Error authenticating payment gateway. Please try again after few seconds.',
        });
      }
    }
  }, [CLIENTSECRET]);

  return (
    <Box marginTop="20px" marginRight="20px" paddingBottom="60px" width="90vw">
      <Loader isLoading={isLoading} />
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={() => setIsNotification(false)}
      />
      <Typography
        style={{
          fontSize: '21px',
          fontWeight: 300,
          marginBottom: '10px',
          letterSpacing: '-0.525px',
          lineHeight: '32px',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
        variant={'h1'}
      >
        Billing and Subscriptions
      </Typography>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" style={{ height: '100%' }}>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <BillingInfo
            packages={PACKAGES?.packages}
            showStripeForm={showStripeForm}
            handleShowStripeForm={handleShowStripeForm}
            trialDays={trialDays}
            trialDaysHandler={trialDaysHandler}
            clientSecret={clientSecret}
            routerChange={routerChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7} style={{ padding: '20px', height: '100%' }}>
          {showStripeForm && clientSecret && (
            <Box marginTop="2vh">
              <Elements options={options} stripe={stripePromise} disabled={true}>
                <StripePaymentForm
                  buttonLabel={'Add Payment Method'}
                  redirectPath={`/superadmin/add-agency/billing-status?agencyId=${AGENCY_DETAILS?.currentAddedAgency?.data?.entity?._id}`}
                />
              </Elements>
              <PromoCode
                usePromoCode={isUsePromoCode}
                onCheckboxSelect={(value) => setIsUsePromoCode(value)}
                redirectPath={`/superadmin/add-agency/billing-status?agencyId=${AGENCY_DETAILS?.currentAddedAgency?.data?.entity?._id}`}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgencyBillingForm;
