import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
/** selectors */
import { SuperBreadCrumbs, AddAgencyForm, AddAgencyFreeForm } from 'components/Web/Superadmin';

import AgencyAccountForm from 'components/Web/Superadmin/Agency/AddAgencyAccount';
import AgencyBillingForm from 'components/Web/Superadmin/Agency/AddAgencyBillingForm';
import AgencyBillingFreeForm from 'components/Web/Superadmin/Agency/AddAgencyBillingFreeForm';
import { superadminActions } from 'store/actions';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
});

const SuperAdminAddAgency = (props) => {
  const { classes, history } = props;

  const [accountType, setAccountType] = useState('Paid');

  const dispatch = useDispatch();

  const clearSuperAdminReduxState = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_ADD_AGENCY', 'CLEAR'));
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'));
  };

  window.onbeforeunload = (e) => {
    return clearSuperAdminReduxState();
  };

  useEffect(() => {
    clearSuperAdminReduxState();
    return () => clearSuperAdminReduxState();
  }, []);

  const [step, setStep] = useState(1);

  const handleFormStep = (type) => {
    setStep((prev) => {
      if (type === 'next') return (prev += 1);
      if (type === 'back') return (prev -= 1);
    });
  };

  const handleChangeAccountType = (e) => {
    setAccountType(e);
  };

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Add Agency',
      path: '/superadmin/add-agency',
    },
  ];

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs section={'Add Agency'} crumbsLinks={CRUMBS} />
      {/*step === 1 && (
        <AgencyAccountForm
          accountType={accountType}
          handleChangeAccountType={handleChangeAccountType}
          nextFormAction={handleFormStep}
        />
      )*/}
      {step === 1 && <AddAgencyForm nextFormAction={handleFormStep} />}
      {/*step === 2 && accountType === 'Free' && <AddAgencyFreeForm nextFormAction={handleFormStep} />*/}
      {step === 2 && <AgencyBillingForm />}
      {/*step === 3 && accountType === 'Free' && <AgencyBillingFreeForm />*/}
    </div>
  );
};
export default withStyles(styles)(SuperAdminAddAgency);
